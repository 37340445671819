<template>
    <div v-if="service">
        <memoryshare-stream :slug="$route.params.slug" :options="options" :test="service.test"></memoryshare-stream>
    </div>
</template>

<script>
export default {
    name: 'IFrameVideoPlayer',
    metaInfo: {
        title: 'MemoryShare',
        titleTemplate: null,
    },
    data() {
        return {
            ready: false,
            backgroundImage: `url(${require('@/assets/images/white-waves.png')})`,
            token: '',
            options: '',
            service: null,
        };
    },
    methods: {
        checkIntercom() {
            let intercomContainer = document.querySelector('#intercom-container-body');
            if (intercomContainer) {
                intercomContainer?.classList.remove('intercom-show');
                intercomContainer.classList.add('intercom-hidden');
            }
        },
    },
    mounted() {
        var i = setInterval(this.checkIntercom(), 100);
        setTimeout(() => {
            clearInterval(i);
        }, 10000);

        let intercomObject = document.querySelector('.intercom-launcher');
        if (intercomObject) {
            intercomObject?.classList.remove('intercom-show');
            intercomObject.classList.add('intercom-hidden');
        }
    },
    created() {
        let width = this.$route.query.width || 300;
        let height = this.$route.query.width || 300;

        let newOptions = {
            fluid: false,
            height,
            width,
            playerOnly: true,
        };

        this.options = JSON.stringify(newOptions);

        this.$store.dispatch('toggleTopNav');

        // Inject JS
        let externalScript = document.createElement('script');
        externalScript.setAttribute('src', process.env.VUE_APP_ASSETS + '/app.v1.0.2.js');
        document.head.appendChild(externalScript);

        // Inject CSS
        let file = document.createElement('link');
        file.rel = 'stylesheet';
        file.href = process.env.VUE_APP_ASSETS + '/app.v1.0.2.css';
        document.head.appendChild(file);

        externalScript.addEventListener('load', () => {
            this.ready = true;
            if (this.$auth.role.includes('FuneralHome') || this.$auth.role.includes('SuperAdmin')) {
                this.$auth.getIdTokenClaims().then(result => {
                    this.token = result.__raw;
                });
            }
        });

        this.axios.get(process.env.VUE_APP_API + `/services/view/${this.$route.params.slug}`).then(response => {
            this.service = { ...response.data };
        });
    },
};
</script>

<style lang="scss" scoped>
.stream-buffer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 10001;
}

.intercom-launcher {
    display: none !important;
}

#intercom-container-body {
    display: none !important;
}

.intercom-container-body {
    display: none !important;
}
#intercom-container-body {
    display: none !important;
}
.intercom-hidden {
    display: none !important;
}
</style>
